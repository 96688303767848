import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  forwardRef,
  input,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../../../shared/services/util.service';
import { LocaleApiModel } from '../../../locale/models/locale.api-model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { v4 as uuidv4 } from 'uuid';
import { TranslationKeyApiModel } from '../../models/translation-key.api-model';
import { TranslationApiModel } from '../../models/translation.api-model';

const uuid = uuidv4;

@Component({
  selector: 'emc-translation-form',
  templateUrl: './translation-form.component.html',
  styleUrls: ['./translation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TranslationFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TranslationFormComponent),
      multi: true,
    },
  ],
})
export class TranslationFormComponent extends CompleteBaseForm implements OnInit {
  public locales = input<LocaleApiModel[]>();
  public model = input<TranslationKeyApiModel>();

  public fields: FormlyFieldConfig[];

  constructor(private translateService: TranslateService,
              private utilService: UtilService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);

    effect(() => {
      if (this.locales() && this.model()) {
        this.setFields(this.locales(), this.model());
      }
    })
  }

  public ngOnInit(): void {
    this.listenToFormChanges();
  }

  public setFields(locales: LocaleApiModel[], model: TranslationKeyApiModel): void {
    this.fields = [
      {
        key: 'key',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('translation.overview.label'),
          disabled: true,
        },
      },
    ];

    if (locales) {
      locales.forEach((locale: LocaleApiModel) => {
        let index = model.translations.findIndex(translation => translation.locale_id === locale.id);

        // Add new translation if not exists yet
        if (index === -1) {
          const newTranslation: TranslationApiModel = {
            id: uuid(),
            locale_id: locale.id,
            translation_key_id: this.model().id,
            translation: '',
          };

          model.translations.push(newTranslation);

          index = model.translations.findIndex(translation => translation.locale_id === locale.id);
        }

        // Add input for translation
        const field = {
          key: `translations.${index}.translation`,
          type: 'input',
          props: {
            translateLabel: locale.name,
            required: true,
          },
        };

        this.fields.push(field);
      });
    }

    this.changeDetectorRef.detectChanges();
    this.updateFormValue();
  }

}
