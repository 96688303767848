import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CapturumTemplateDirective,
  FilterMatchMode,
  ItemExpression,
  LocalStorageService,
  StyleClassExpression,
  TableRouteConfigService,
  TableText,
} from '@capturum/ui/api';
import { FilterMetadata, LazyLoadEvent, SelectItem, TableState } from 'primeng/api';
import { Table, TableColumnReorderEvent } from 'primeng/table';
import { take } from 'rxjs/operators';
import { ColumnOption } from './base/column-option.model';
import { InfoTableColumnType } from './base/info-table-column-type.enum';
import { InfoTableColumn, InfoTableSortColumnEvent } from './base/info-table-column.model';
import { InfoTableConfigService } from './services/info-table-config.service';
import { TranslateService } from '@ngx-translate/core';

export interface RouteTableConfig {
  filters: RouteTableFilters;
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: number;
}

export interface RouteTableFilters {}

export interface TotalsData {
  [key: string]: number;
}

@Component({
  selector: 'cap-info-table',
  templateUrl: './info-table.component.html',
})
export class CapturumInfoTableComponent
  implements OnInit, AfterContentInit, OnChanges, OnDestroy, StyleClassExpression
{
  /**
   * The columns to be used by the table
   */
  @Input() set columns(value: InfoTableColumn[]) {
    if (value) {
      this.columnOptions = value
        .filter((column) => {
          return column.type !== InfoTableColumnType.Actions && !column.hidden && !!column.title;
        })
        .map((column) => {
          return {field: column.field, visible: true, title: column.title};
        });
    }

    if (this.reorderableColumns) {
      value = [...this.restoreColumnOrderFromState(value)];
    }

    if (this.showVisibilityToggler) {
      value = [...this.restoreHiddenColumnsFromState(value)];
    }

    this._columns = value;
    this.visibleColumns = value;

    if (value) {
      this.hasFrozenColumns = value.some((column) => {
        return column.frozen;
      });
      this.visibleColumnsLength = value.filter((column) => {
        return !column.hidden;
      }).length;
    }
  }

  get columns(): InfoTableColumn[] {
    return this.visibleColumns;
  }

  /**
   * The rows number to be displayed in the table
   */
  @Input('rows') set setRows(value: number) {
    this.rows = value;
  }

  /**
   * Define whether the table should use lazy loading
   */
  @Input('lazyLoading') set setLazyLoading(value: boolean) {
    this.lazyLoading = value;
  }

  /**
   * The property that defines whether the table is sortable or not
   */
  @Input('sortable') set setSortable(value: boolean) {
    this.sortable = value;
  }

  /**
   * The property that decides the sortMode. Options are single and multiple
   */
  @Input('sortMode') set setSortMode(value: 'single' | 'multiple') {
    this.sortMode = value;
  }

  /**
   * Indicates if table records are selectable
   */
  @Input('selectable') set setSelectable(value: boolean) {
    this.selectable = value;
  }

  /**
   * Whether to show the toggler for visibility
   */
  @Input('showVisibilityToggler') set setShowVisibilityToggler(value: boolean) {
    this.showVisibilityToggler = value;
  }

  /**
   * The icon to use for the visibility toggler
   */
  @Input('visibilityTogglerIcon') set setVisibilityTogglerIcon(value: string) {
    this.visibilityTogglerIcon = value;
  }

  /**
   * A property to display table rows as cards
   */
  @Input()
  public cardsView = true;

  /**
   * A property to uniquely identify a record in data.
   */
  @Input('dataKey') set setDataKey(value: string) {
    this.dataKey = value;
  }

  /**
   * Options for 'per page' dropdown
   */
  @Input('perPageOptions') set setPerPageOptions(value: SelectItem[]) {
    this.perPageOptions = value;
  }

  @Input('texts') set setTexts(value: Partial<TableText>) {
    this.texts = { ...this.texts, ...value };
  }

  /**
   * Define whether the table rows are clickable
   */
  @Input('clickable') set setClickable(value: boolean) {
    this.clickable = value;
  }

  /**
   * Define whether the table should show pagination
   */
  @Input('pagination') set setPagination(value: boolean) {
    this.pagination = value;
  }

  /**
   * Whether the cell widths scale according to their content or not
   */
  @Input('autoLayout') set setAutoLayout(value: boolean) {
    this.autoLayout = value;
  }

  /**
   * Define whether the table should use virtual scroll
   */
  @Input('virtualScroll') set setVirtualScroll(value: boolean) {
    this.virtualScroll = value;
  }

  /**
   * Define the scroll height of the table when it uses virtual scroll
   */
  @Input('scrollHeight') set setScrollHeight(value: string) {
    this.scrollHeight = value;
  }

  /**
   * Define the row height of the table when it uses virtual scroll
   */
  @Input('virtualScrollItemSize') set setVirtualScrollItemSize(value: number) {
    this.virtualScrollItemSize = value;
  }

  /**
   * Styleclass to be applied to the table
   */
  @Input('styleClass') set setStyleClass(value: string) {
    this.styleClass = value;
  }

  public get activeFilters(): { [key: string]: FilterMetadata | FilterMetadata[] } {
    return this.primeNGTable && this.primeNGTable.filters;
  }

  /**
   * Define whether the rows are editable
   */
  @Input('editableRows')
  public set setEditableRows(editable: boolean) {
    this.isEditableRows = editable;
  }

  /**
   * Define enabled with paginator and checkbox selection mode
   */
  @Input() public selectionPageOnly: boolean;

  public get editableRowsTogglerPosition(): 'start' | 'end' | number {
    return this._editableRowsTogglerPosition;
  }

  /**
   * Define position of the toggle button of editable rows
   */
  @Input()
  public set editableRowsTogglerPosition(position: 'start' | 'end' | number) {
    this.setEditableRowsTogglerPosition(position);
  }

  /**
   * The property that defines whether to save the filters in the route params or not
   */
  @Input()
  public routeFilters = false;

  /**
   * An array of objects to display
   */
  @Input() public data: any[];

  /**
   * The property that defines a unique state key for remembering filters, sorting and pagination. If not set, then state is not used.
   */
  @Input() public stateKey: string;
  /**
   * The paginator to be used by the table
   */
  @Input() public paginator: {
    rows: number;
    total: number;
    current_page: number;
    total_pages: number;
    per_page: number;
    first?: number;
  };

  /**
   * Value of selected rows
   */
  @Input() public selectedRows: any[];

  /**
   * Define whether the table is in a loading state and should therefor show a loading spinner
   */
  @Input() public loading?: boolean;

  /**
   * Function to optimize the dom operations by delegating to ngForTrackBy, default algoritm checks for object identity.
   */
  @Input() public rowTrackBy: Function = null;

  /**
   * Define whether the columns can be reorderable
   */
  @Input() public reorderableRows: boolean;

  /**
   * Define icon for reoderable rows
   */
  @Input() public reorderableRowIcon = 'fas fa-grip-vertical';

  /**
   * Define whether the table should be scrollable
   */
  @Input() public scrollable: boolean;

  /**
   * Define whether the table should be in edit mode
   */
  @Input() public isEdit: boolean;

  /**
   * Enable edit table
   */
  @Input('editTableMode')
  public set setTableEditing(value: boolean) {
    if (value) {
      if (this.isEditableRows) {
        throw new Error(`[editTableMode] option can't be used in combination with [isEditableRows]`);
      }

      this.editTable = value;
    }
  }

  /**
   * A callback which returns a styleClass based on the row
   */
  @Input() public styleClassExpression?: ItemExpression;

  /**
   * Whether to call lazy loading on initialization.
   */
  @Input() public lazyLoadOnInit = true;

  /**
   * Define whether the columns can be reordered
   */
  @Input() public reorderableColumns = false;

  /**
   * When enabled, columns can be resized using drag and drop.
   */
  @Input()
  public resizableColumns: boolean;

  @Input()
  public columnResizeMode: 'fit' | 'expand' = 'fit';

  /**
   * Define whether the pagination should show first and last page buttons
   */
  @Input()
  public showFirstLastIcon = false;

  /**
   * Callback to invoke when a row is selected
   */
  @Output() public onRowClick = new EventEmitter<any>();

  /**
   * Callback to invoke when a row is selected and the ctrl(meta) key is pressed
   */
  @Output() public onRowCtrlClick = new EventEmitter<any>();

  /**
   * Callback to invoke when a column is selected
   */
  @Output() public onColumnClick = new EventEmitter<{ column: string; row: any }>();

  /**
   * Callback to invoke when paging, sorting or filtering happens in lazy mode
   */
  @Output() public onLazyLoad = new EventEmitter<LazyLoadEvent>();

  /**
   * Callback to invoke when state of row checkbox changes. This passes the selected row
   */
  @Output() public onRowToggle = new EventEmitter<any>();

  /**
   * Callback to invoke when the table is sorted
   */
  @Output() public onSort = new EventEmitter<InfoTableSortColumnEvent>();

  /**
   * Callback to invoke when changing pages
   */
  @Output() public onPage = new EventEmitter<any>();

  /**
   * Callback to invoke when number of rows changes
   */
  @Output() public onNumRowsChange = new EventEmitter<any>();

  /**
   * Callback to invoke when row was reordered
   */
  @Output() public onRowReorder = new EventEmitter<{ dragIndex: number; dropIndex: number }>();

  /**
   * Callback to invoke when a column is reordered.
   * @param {TableColumnReorderEvent} event - custom column reorder event.
   * @group Emits
   */
  @Output() onColReorder: EventEmitter<TableColumnReorderEvent> = new EventEmitter<TableColumnReorderEvent>();

  /**
   * Callback to invoke when sort is clicked
   */
  @Output() public sortClick: EventEmitter<InfoTableColumn> = new EventEmitter<InfoTableColumn>();
  @Output() public onStateRestore = new EventEmitter<any>();
  @Output() public selectedRowsChange = new EventEmitter<any>();
  /** Callback to invoke when row edit button is clicked */
  @Output() public onRowEditClick = new EventEmitter<any>();
  /** Callback to invoke when row save button is clicked */
  @Output() public onRowEditSaveClick = new EventEmitter<any>();
  /** Callback to invoke when row cancel button is clicked */
  @Output() public onRowEditCancelClick = new EventEmitter<{ item: any; index: number }>();

  @ContentChildren(CapturumTemplateDirective) public templates: QueryList<any>;
  @ViewChild(Table) public primeNGTable: Table;

  public visibleColumns: InfoTableColumn[];
  public columnOptions: ColumnOption[];
  public perPageOptions: SelectItem[];
  public showVisibilityToggler: boolean;
  public visibilityTogglerIcon: string;
  public scrollHeight: string;
  public styleClass: string;
  public dataKey: string;
  public clickable: boolean;
  public selectable: boolean;
  public lazyLoading: boolean;
  public autoLayout: boolean;
  public virtualScroll: boolean;
  public rows: number;
  public sortable: boolean;
  public pagination: boolean;
  public virtualScrollItemSize: number;
  public sortMode: 'single' | 'multiple';
  public texts: Partial<TableText> = {};
  public hasFrozenColumns = false;
  public isEditableRows = false;
  public footerTemplate: TemplateRef<any>;
  public editTable: boolean;
  public visibleColumnsLength = 0;
  public totalColumns: InfoTableColumn[] = [];
  public totalsData: TotalsData[];
  public hasAtLeastOneTemplates: boolean;

  private _columns: InfoTableColumn[];
  private _editableRowsTogglerPosition: 'start' | 'end' | number;

  private translateService = inject(TranslateService);

  constructor(
    private readonly infoTableConfigService: InfoTableConfigService,
    private readonly localStorageService: LocalStorageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly tableFiltersRouteConfig: TableRouteConfigService,
  ) {
    this.fetchInfoTableConfig();
  }

  public ngOnInit(): void {
    if (this.routeFilters) {
      const routeConfig = this.activatedRoute.snapshot.queryParams;
      const routeTableConfig = Object.keys(routeConfig || {});

      if (routeTableConfig.length) {
        const localTableConfig = this.localStorageService.getItem(this.stateKey) || {};

        if (routeConfig.sortField && routeConfig.sortOrder) {
          localTableConfig.sortField = routeConfig.sortField;
          localTableConfig.sortOrder = routeConfig.sortOrder;
        }

        if (routeConfig.page) {
          localTableConfig.page = routeConfig.page;
        }

        if (routeConfig.perPage) {
          localTableConfig.perPage = routeConfig.perPage;
        }

        localTableConfig.selection = [];

        this.localStorageService.setItem(this.stateKey, localTableConfig);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue !== changes?.data?.previousValue) {
      this.totalColumns = this.handleTotalsColumns(this._columns);
      this.totalsData = this.calculateTotals(this.data, this.totalColumns);
      this.hasAtLeastOneTemplates =
        this.totalColumns.filter((item) => {
          return item.type === InfoTableColumnType.Template;
        }).length >= 1;
    }
  }

  public ngAfterContentInit(): void {
    this.footerTemplate = this.templates.find((template) => {
      return template.getType() === 'infoTableFooter';
    })?.template;
  }

  public ngOnDestroy(): void {
    // Avoid primeng to remember in local Storage the previous selected items
    if (this.selectedRows?.length) {
      this.selectedRows = [];

      const localTableConfig = this.localStorageService.getItem(this.stateKey) || {};

      localTableConfig.selection = [];

      this.localStorageService.setItem(this.stateKey, localTableConfig);
    }
  }

  public trackByFn(index: number, column: InfoTableColumn): string {
    return column.field;
  }

  public sortClickHandler(column: InfoTableColumn): void {
    if (column.sortable && column.sortable.enabled) {
      let direction = column.sortable.direction;

      this.sortClick.emit(column);

      if (direction) {
        direction = direction === 'asc' ? 'desc' : 'asc';
      } else {
        direction = 'asc';
      }
    }
  }

  public handleTotalsColumns(columns: InfoTableColumn[]): InfoTableColumn[] {
    const result: InfoTableColumn[] = [];
    let isFirstColumnExcludedFromSum = true;

    columns.forEach((column) => {
      if (column.showTotals) {
        // add the calculated field
        result.push({
          field: column.field,
          type: InfoTableColumnType.Template,
        });
      } else if (isFirstColumnExcludedFromSum) {
        // replace the first non-calculated column with the 'title' field
        result.push({
          field: 'title',
          type: InfoTableColumnType.Text,
        });

        isFirstColumnExcludedFromSum = false;
      } else {
        // add empty field for non-calculated columns
        result.push({
          field: '',
        });
      }
    });

    return result;
  }

  public calculateTotals(data: Record<string, any>[], totalColumns: Record<string, any>[]): TotalsData[] {
    const totalsFields = totalColumns
      .map((column) => {
        return column.field;
      })
      .filter((field) => {
        return field && field !== 'title';
      });

    const totals = totalsFields.reduce(
      (acc, field) => {
        const total = data?.reduce((sum, item) => {
          if (typeof item[field] === 'number') {
            return sum + item[field];
          } else {
            return null;
          }
        }, 0);

        if (total >= 0) {
          acc[field] = total;
        }

        return acc;
      },
      {} as Record<string, number>,
    );

    return [{ ...totals, title: this.translateService.instant('builders.table.totals.label') }];
  }

  public filterTable(value: any, field: string, matchMode: FilterMatchMode): void {
    this.primeNGTable.filter(value, field, matchMode);
  }

  public resetFilters(): void {
    this.primeNGTable.clearState();
    this.primeNGTable.reset();
  }

  public globalFilter(value: any, field: string, matchMode: string): void {
    this.primeNGTable.filterGlobal(value, matchMode);
  }

  public loadTableData($event: any): void {
    this.onLazyLoad.emit($event);
  }

  public onSortColumn({ field, order }): void {
    if (this.routeFilters) {
      this.tableFiltersRouteConfig.saveConfigInParams({
        sortField: field,
        sortOrder: order,
      });
    }

    this.onSort.emit({
      current: {
        field,
        order,
      },
      lazyLoadMetaData: this.primeNGTable?.createLazyLoadMetadata() || {},
    });
  }

  public onReorderColumn(event: TableColumnReorderEvent): void {
    this.visibleColumns = [ ...event.columns];

    this.onColReorder.emit(event);
  }

  public onPerPageChange(perPage: number): void {
    if (this.stateKey) {
      const localStorageItem = this.localStorageService.getItem(this.stateKey) || {};

      localStorageItem.rows = perPage;
      localStorageItem.per_page = perPage;
      localStorageItem.first = 0;

      if (this.routeFilters) {
        this.tableFiltersRouteConfig.saveConfigInParams({
          perPage,
        });
      }

      this.localStorageService.setItem(this.stateKey, localStorageItem);
    }

    const lazyLoadMeta = this.primeNGTable?.createLazyLoadMetadata() || {};

    this.loadTableData({ ...lazyLoadMeta, rows: perPage, per_page: perPage, first: 0 });
  }

  public toggleRowSelect(event: any, checked: boolean): void {
    this.onRowToggle.emit({ event, checked });
  }

  public setVisibility(updateVisibleColumns = true): void {
    const columns: InfoTableColumn[] = [];
    const hiddenColumns: string[] = [];

    for (const column of this._columns) {
      const visible =
        this.columnOptions.findIndex((option) => {
          return option.field === column.field && option.visible === true;
        }) >= 0;

      if (visible || column.type === InfoTableColumnType.Actions) {
        column.hidden = false;

        columns.push(column);
      } else {
        hiddenColumns.push(column.field);
      }
    }

    if (this.stateKey) {
      let localStorageItem = this.localStorageService.getItem(this.stateKey) || {};

      if (localStorageItem.columnWidths) {
        // Get rid of column widths
        delete localStorageItem.columnWidths;
      }

      if (localStorageItem.columnOrder) {
        localStorageItem.columnOrder = columns.map((column) => {
          return column.field;
        });
      }

      this.localStorageService.setItem(this.stateKey, localStorageItem);
      this.localStorageService.setItem(`${this.stateKey}_hidden_columns`, hiddenColumns);
    }

    if (updateVisibleColumns) {
      this.visibleColumns = [...columns];
    }
  }

  public onSelectionChange(): void {
    this.selectedRowsChange.emit(this.selectedRows);
  }

  private fetchInfoTableConfig(): void {
    this.infoTableConfigService
      .getConfig()
      .pipe(take(1))
      .subscribe((config) => {
        this.texts = { ...config.defaultTexts, ...this.texts };

        const excludedKeys = ['defaultTexts', 'texts', 'cardBreakpoint'];
        const tableInputs = Object.keys(config).filter((key) => {
          return !excludedKeys.includes(key);
        });

        tableInputs.forEach((key) => {
          return (this[key] = config[key]);
        });
      });
  }

  private setEditableRowsTogglerPosition(position: 'start' | 'end' | number): void {
    let startPosition: number;

    if (this.isEditableRows) {
      switch (true) {
        case position === 'start' || (position as number) <= 0:
          startPosition = 0;
          break;

        case position === 'end' || (position as number) >= this._columns.length - 1:
          startPosition = this._columns.length;
          break;

        default:
          startPosition = position as number;
      }

      this._editableRowsTogglerPosition = startPosition;
      const togglePosition: InfoTableColumn = {
        field: '',
        visible: this.isEditableRows ?? false,
        type: InfoTableColumnType.EditButtons,
        title: '',
        titleClass: 'column-header--editable-rows',
        cellClass: 'editable-rows-buttons',
      };

      this._columns.splice(startPosition, 0, togglePosition);
    }
  }

  public onStateRestored(state: TableState & { hiddenColumns?: string[] }): void {
    this.onStateRestore.emit(state);
  }

  public onCdkRowReorder(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);

    this.onRowReorder.emit({ dragIndex: event.previousIndex, dropIndex: event.currentIndex });
  }

  private restoreColumnOrderFromState(columns: InfoTableColumn[]): InfoTableColumn[] {
    let reorderedColumns: InfoTableColumn[] = [];
    const state = this.localStorageService.getItem(this.stateKey) || {};
    const hiddenColumns = this.localStorageService.getItem(`${this.stateKey}_hidden_columns`) || [];

    if (!state.columnOrder) {
      return columns;
    }

    state.columnOrder.map((key: string) => {
      let column = columns.find((col) => {
        return col.field === key;
      });

      if (column) {
        reorderedColumns.push(column);
      }
    });

    hiddenColumns.map((key: string)=> {
      let column = columns.find((col) => {
        return col.field === key;
      });

      if (column) {
        reorderedColumns.push(column);
      }
    });

    return reorderedColumns;
  }

  private restoreHiddenColumnsFromState(columns: InfoTableColumn[]): InfoTableColumn[] {
    const hiddenColumns = this.localStorageService.getItem(`${this.stateKey}_hidden_columns`) || [];

    if (hiddenColumns.length === 0) {
      return columns;
    }

    this.columnOptions.map((columnOption) => {
      if (hiddenColumns.includes(columnOption.field)) {
        columnOption.visible = false;
      }

      return columnOption;
    });

    return columns.map((column) => {
      if (hiddenColumns.includes(column.field)) {
        column.hidden = true;
      }

      return column;
    });
  }
}
