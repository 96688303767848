<!--Filters-->
<div
  class="cpb-list-renderer"
  [class.bulk-actions-opened]="selectedRows.length > 0"
  [attr.data-test]="'builder-' + key">
  <div *ngIf="headerActions.length" class="cpb-header-actions">
    <cpb-list-renderer-header-actions [actions]="headerActions"></cpb-list-renderer-header-actions>
  </div>

  <div
    #header
    class="header"
    [ngClass]="{ 'header--sticky': headerType === HeaderType.Sticky, 'd-flex': configureTableSettings }">
    <cpb-list-renderer-filters
      *ngIf="dynamicFilters"
      [sidebarFiltering]="sidebarFiltering"
      [config]="filtersConfig"
      [routeFilters]="saveFiltersInUrl"
      [filters]="dynamicFilters"
      [key]="stateKey"
      (filterReset)="handleResetFilter()"
      (filtersChanges)="fetchFilters($event)">
    </cpb-list-renderer-filters>

    <cap-button
      *ngIf="configureTableSettings && dynamicFilters"
      styleClass="primary"
      class="table-settings-button ms-2"
      icon="fas fa-cog"
      (onClick)="openTableSettingsSidebar()">
    </cap-button>
  </div>

  <div
    #content
    class="content"
    [class.table-columns-view]="!cardsView"
    [ngClass]="{
      'content--sticky': paginationType === PaginationType.InfiniteScroll && topScroll > 50,
      'content--initial-sticky': paginationType === PaginationType.InfiniteScroll && topScroll <= 50,
      'content--virtual-scroll': paginationType === PaginationType.VirtualScroll,
      'table-header--sticky': headerType === HeaderType.Sticky,
    }">
    <cap-info-table
      [showVisibilityToggler]="showVisibilityToggler"
      [stateKey]="stateKey"
      [routeFilters]="saveFiltersInUrl"
      [dataKey]="dataKey"
      [scrollable]="scrollable"
      [data]="data"
      [lazyLoading]="true"
      [cardsView]="cardsView"
      [loading]="loading"
      [pagination]="false"
      [virtualScroll]="paginationType === PaginationType.VirtualScroll"
      [virtualScrollItemSize]="50"
      [rows]="rows"
      [texts]="infoTableTexts"
      [scrollHeight]="scrollHeight"
      [selectable]="bulkActions.length > 0"
      [selectedRows]="selectedRows"
      [reorderableRows]="!!dragDropAction"
      [styleClass]="tableStyleClass"
      [styleClassExpression]="rowStyleClassExpression"
      [resizableColumns]="resizableColumns"
      [reorderableColumns]="reorderableColumns"
      [columns]="columns"
      (onLazyLoad)="loadTableData($event)"
      (onRowClick)="handleOnRowClick($event, false)"
      (onRowCtrlClick)="handleOnRowClick($event, true)"
      (selectedRowsChange)="handleRowToggle($event)"
      (onRowReorder)="handleDropEvent()"
      (onColumnClick)="handleColumnClick($event)">
      <ng-template capTemplate="actions" let-item="item" let-index="index">
        <ng-container *ngIf="tableActionsTemplate; else defaultTableActions">
          <ng-container
            [ngTemplateOutlet]="tableActionsTemplate"
            [ngTemplateOutletContext]="{ tableActions: tableActions, item: item, index: index }">
          </ng-container>
        </ng-container>

        <ng-template #defaultTableActions>
          <cap-row-action [actions]="tableActions | filterByHiddenExpression: item" [item]="item"> </cap-row-action>
        </ng-template>
      </ng-template>

      <ng-container *ngFor="let column of columns">
        <ng-template [capTemplate]="column.field" let-item="item" let-index="index">
          <cpb-list-renderer-dynamic-widget
            [column]="column"
            [attr.data-test]="'builder-list-cell-' + index + '-' + key"
            [index]="index"
            [widget]="column?.columnType"
            [context]="context"
            [widgetOptions]="column.widget?.options"
            [item]="item">
          </cpb-list-renderer-dynamic-widget>
        </ng-template>
      </ng-container>

      <ng-template let-columns capTemplate="infoTableFooter">
        <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: columns }"></ng-container>
      </ng-template>
    </cap-info-table>
  </div>

  <!--Totals side-->
  @if (hasAtLeastOneTotal) {
    <cpb-list-renderer-totals [columns]="totalColumns" [data]="totalsData" [stateKey]="stateKey" [loading]="loading">
    </cpb-list-renderer-totals>
  }

  <!--Paginator side-->
  <cpb-list-renderer-paginator
    *ngIf="paginationType === PaginationType.Paginated"
    [loading]="loading"
    [paginator]="paginator"
    [perPageOptions]="perPageOptions"
    [showFirstLastIcon]="showFirstLastIcon"
    (changePage)="changePage($event)"
    (changePerPage)="changePerPage($event)">
  </cpb-list-renderer-paginator>

  <cpb-list-renderer-bulk-actions
    *ngIf="selectedRows.length > 0 && bulkActions.length > 0"
    [actions]="bulkActions"
    [contentTemplate]="bulkActionsTemplate"
    [selectedItems]="selectedRows"
    (cancelSelection)="handleCancelSelection()"
    (actionExecuted)="handleCancelSelection()">
  </cpb-list-renderer-bulk-actions>

  <cap-cta-button [menuItems]="headerActions | tableActionToCtaButtonItem"></cap-cta-button>
</div>

<cap-sidebar
  *ngIf="showSettingsSidebar"
  [(visible)]="showSettingsSidebar"
  styleClass="cap-dynamic-filters-sidebar"
  [title]="'table-settings.title' | translate">
  <cpb-list-renderer-settings-sidebar
    [stateKey]="tableSettingsStateKey"
    [config]="config | async"
    (onSettingsChange)="onSettingsChange($event)"
    (onSidebarClose)="showSettingsSidebar = false">
  </cpb-list-renderer-settings-sidebar>
</cap-sidebar>
