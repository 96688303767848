<div class="form-element">
  <label
    *ngIf="(label || hasTooltip) && !floatLabel"
    [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <ng-container *ngIf="!floatLabel; else floatingInput">
    <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
  </ng-container>

  <ng-template #floatingInput>
    <span class="p-float-label multi-select-float-label">
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
      <label class="cpb-float-label" htmlFor="float-label">{{ label }}</label>
    </span>
  </ng-template>

  <ng-template #defaultTemplate>
    <p-multiSelect
      (onBlur)="blur.emit($event)"
      (onChange)="onChange($event)"
      (onClick)="click.emit($event)"
      (onFocus)="focus.emit($event)"
      (onFilter)="filtered.emit($event)"
      (onPanelShow)="panelShow.emit($event)"
      (onPanelHide)="panelHide.emit($event)"
      [(ngModel)]="value"
      [appendTo]="appendTo"
      [placeholder]="defaultLabel"
      [disabled]="disabled"
      [filterBy]="filterBy"
      [filterPlaceHolder]="filterPlaceHolder"
      [filter]="filter"
      [inputId]="inputId"
      [virtualScrollItemSize]="virtualScrollItemSize"
      [options]="options"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [class.readonly]="readonly"
      [dataKey]="dataKey"
      [showToggleAll]="showToggleAll"
      [panelStyleClass]="'cap-multi-select-panel ' + panelStyleClass"
      [readonly]="readonly"
      [selectionLimit]="selectionLimit"
      [scrollHeight]="scrollHeight"
      [dropdownIcon]="dropdownIcon"
      [showHeader]="showHeader"
      [emptyMessage]="emptyMessage || ('table.no_results' | translate)"
      [emptyFilterMessage]="emptyFilterMessage || ('table.no_results' | translate)"
      [displaySelectedLabel]="displaySelectedLabel"
      [maxSelectedLabels]="maxSelectedLabels"
      [resetFilterOnHide]="resetFilterOnHide"
      [selectedItemsLabel]="selectedItemsLabel"
      [styleClass]="'cap-multi-select ' + styleClass"
      [group]="group"
      [display]="display"
      [optionGroupLabel]="optionGroupLabel"
      [optionGroupChildren]="optionGroupChildren"
      [virtualScroll]="virtualScroll"
      [autofocusFilter]="autofocusFilter"
      #pMultiSelect>
      @if (itemTemplate) {
        <ng-template pTemplate="item" let-item>
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      }

      @if (clearIconTemplate) {
        <ng-template pTemplate="removetokenicon" let-item>
          <ng-container
            [ngTemplateOutlet]="clearIconTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      }

      @if (selectedItemsTemplate) {
        <ng-template pTemplate="selectedItems" let-items>
          <ng-container
            [ngTemplateOutlet]="selectedItemsTemplate"
            [ngTemplateOutletContext]="{ $implicit: items }"></ng-container>
        </ng-template>
      }

      @if (!selectedItemsTemplate && icon) {
        <ng-template pTemplate="selectedItems" let-items>
          <span [ngClass]="{ 'p-multiselected-empty-token p-corner-all': !items || items.length === 0 }">
            <i *ngIf="icon" class="p-multiselected-icon {{ icon }}"></i>
            {{ !items || items?.length === 0 ? defaultLabel : interpolatedSelectedLabel }}
          </span>
        </ng-template>
      }

      @if (footerTemplate) {
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      }
    </p-multiSelect>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
