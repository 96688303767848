import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import Dexie from 'dexie';
import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CompleteConfig } from '../../complete-config';
import { FileIndexedDbModel } from '../indexedDb/file.indexeddb.model';

import { IndexedDbModel } from '../indexedDb/indexedDb.model';
import { IndexedDbService } from '../indexedDb/indexedDb.service';


export interface SyncConfig {
  syncModels: SyncModel[];
  syncUrl: string;
}

export interface SyncModel {
  modelClass: any;
  include: string[];
}

export interface SyncMeta {
  include: { [key: string]: string };
}

export enum SyncErrorType {
  VALIDATION = 'validation',
  EXCEPTION = 'exception',
}

export interface SyncResponse {
  [key: string]: {
    deleted: string[];
    mutated: any[];
    errors: {
      type: SyncErrorType;
      data: any;
    }[];
  };
}

export interface Syncable {
  models: SyncModel[];

  sync(): Observable<SyncResponse>;

  retrieveSyncData(): Observable<{ data: { [key: string]: any[] }, meta: SyncMeta }>;

  storeResponse(response: any, deleteResourcesAfterSync: boolean): Observable<any>;
}

@Injectable()
export class SyncService implements Syncable {
  public models = [];

  constructor(private apiService: ApiService<any>,
              private coreConfig: CompleteConfig,
              private indexedDBService: IndexedDbService) {
    if (this.coreConfig.syncConfig) {
      this.models = this.coreConfig.syncConfig.syncModels;
    }
  }

  /**
   * Send the data to the backend and store the result
   */
  public sync(deleteResourcesAfterSync: boolean = true): Observable<SyncResponse> {
    return this.retrieveSyncData().pipe(
      switchMap(({ data, meta }) => this.syncApiPost(data, meta)),
      switchMap((response) => this.storeResponse(response, deleteResourcesAfterSync))
    );
  }

  /**
   * Retrieve the data from the indexeddb based on the given models
   */
  public retrieveSyncData(): Observable<{ data: { [key: string]: any[] }, meta: SyncMeta }> {
    const data = {};
    const meta = { include: {} };
    const store = this.indexedDBService.getStore();

    const tables = this.models.map((model) => {
      return IndexedDbModel.store.table(new model.modelClass({}).table);
    });

    return new Observable((observer) => {
      IndexedDbModel.store.transaction('rw', tables, async () => {
        for (const model of this.models) {
          const table = new model.modelClass({}).table;

          data[table] = await store.table(table).where({ is_changed: 1 }).or('_deleted').equals(1).toArray();
          meta.include[table] = model.include.join(',');
        }
      }).then(() => {
        observer.next({ data, meta });
      }).catch((error) => {
        observer.error(error);
      }).finally(() => {
        observer.complete();
      });
    });
  }

  /**
   * Store the response in the indexeddb
   *
   * @param response - response of the sync call
   * @param deleteResourcesAfterSync - define if the resources should be deleted after a successfull sync
   */
  public storeResponse(response: SyncResponse, deleteResourcesAfterSync: boolean): Observable<any> {
    return new Observable((observer) => {
      IndexedDbModel.store.transaction('rw', this.getTables(), async () => {
        if (deleteResourcesAfterSync) {
          for (const { modelClass } of this.models) {
            if (response.hasOwnProperty(new modelClass({}).table) && response[new modelClass({}).table].hasOwnProperty('errors')) {
              await modelClass.query().where('id').noneOf(response[new modelClass({}).table].errors.map((error) => error.data.id)).delete();
            }
          }
        }
      }).then(() => {
        observer.next(response);
      }).catch((error) => {
        observer.error(error);
      }).finally(() => observer.complete());
    });
  }

  /**
   * Retrieve all file from files table which are changed or deleted and send those to the backend
   */
  public syncFiles(): Observable<any> {
    return new Observable((observer) => {
      const requests: Observable<any>[] = [];

      FileIndexedDbModel.query().where({ _changed: 1 }).or('_deleted').equals(1).toArray().then((fileRecords) => {
        if (fileRecords.length === 0) {
          observer.next(null);
          observer.complete();

          return;
        }

        for (const file of fileRecords) {
          const fileModel = new FileIndexedDbModel({ ...file }).getData();

          const base64 = btoa(
            new Uint8Array(file.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );

          const request = this.syncApiFilePost(
            fileModel.relatedTable,
            fileModel.relatedId,
            {
              id: file.id,
              data: base64,
              mimetype: fileModel.mime_type,
              filename: fileModel.filename,
              _deleted: fileModel._deleted
            }
          ).pipe(map((response) => response.data), catchError((error) => of(error)));

          requests.push(request);
        }

        forkJoin(requests).subscribe((responses) => {
            let errors = responses.filter((response) => response.error).map((error) => error.id);
            const successResponses = responses.filter((response) => !response.error);

            for (const response of successResponses) {
              for (const table in response) {
                if (response.hasOwnProperty(table)) {
                  for (const id in response[table]) {
                    if (response[table][id].errors.length > 0) {
                      errors = errors.concat(response[table][id].errors.map((error) => error.data.id));
                    }
                  }
                }
              }
            }

            FileIndexedDbModel.query().where('id').noneOf(errors).delete().then(() => {
              observer.next({ responses });
              observer.complete();
            }).catch((error) => {
              observer.error(error);
            });

            observer.next({ responses });
            observer.complete();
          }
        );
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  /**
   * Send sync data to backend
   *
   * @param data - the data to be synced
   * @param meta - this defines what realtions are included
   */
  public syncApiPost(data: { [key: string]: any[] }, meta: SyncMeta): Observable<SyncResponse> {
    return this.apiService.apiHttp.post<{ data: SyncResponse }>(this.coreConfig.syncConfig.syncUrl, {
      data,
      _meta: meta
    }).pipe(map((response) => response.data));
  }

  /**
   * Send the file to the backend
   *
   * @param table - the table the file belongs to
   * @param id - the id of the entity the file belongs to
   * @param data - the file itself as base64
   */
  public syncApiFilePost(table: string, id: string, data: { data: string, id: string, mimetype: string, filename: string, _deleted?: number }): Observable<any> {
    const postBody = {
      [table]:
        {
          [id]: [
            data
          ]
        }
    };

    return this.apiService.apiHttp.post<{ data: SyncResponse }>(`${this.coreConfig.syncConfig.syncUrl}/file`, { data: postBody })
      .pipe(map((response) => response));
  }

  /**
   * Retrieve tables that need to be synced
   */
  private getTables(): Dexie.Table<any, any>[] {
    return this.models.map((model) => {
      return IndexedDbModel.store.table(new model.modelClass({}).table);
    });
  }
}
